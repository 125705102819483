/* width */
::-webkit-scrollbar {
  width: 5px;
}
iframe
{
    display: none;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e89c9; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e89c9; 
}

.conatainer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.msgTips{
  position: absolute;
    right: 55px;
    bottom: 13px;
    z-index: 10;
}

.rightborder {
  border-right: solid #ebebeb 1px;
}
.btns {
  padding: 0px 10px;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.padd {
  padding-top: 5rem;
}
.ml-1 {
  margin-left: 0.5em;
}
.br {
  border-radius: 20px;
}
.passIcon {
  position: relative;
  right: 16px;
  bottom: 35px;
  float: right;
}
.item-medias {
  max-height: 130px;
  max-width: 130px;
  width: 130px;
  height: 130px;
  object-fit: contain;
  border-radius: 5px;
}
.gallery_image_uploads {
  max-height: 130px;
  max-width: 130px;
  width: 130px;
  height: 130px;
  margin: auto;
}
.setremvemedia {
  position: absolute;
  right: 0px;
  color: coral;
}
.addmideaposition {
  /* width: 100%;
    position: relative;
    left: 18%;
    bottom: -50px;
    max-height: 130px;
    max-width: 130px;
    width: 130px;
    height: 130px;
    margin: auto; */
  border: solid #e7e4e4 1px;
  width: 100%;
  max-height: 130px;
  max-width: 130px;
  width: 100px;
  height: 130px;
  border-radius: 10px;
  margin: auto;
}
.selectImage {
  width: 100px;
  height: 130px;
}
.selectImageICON {
  position: absolute;
  bottom: -55px;
  left: 38px;
}
.img-fluidsProfile {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.uiicons {
  font-size: 18px;
  /* margin-left: 10px; */
}
.img-fluid-post {
  border-radius: 100%;
  width: 65px;
  height: 65px;
}

.commentUserImg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.card-titles h3 {
  text-transform: capitalize;
  color: hsl(203, 7%, 34%);
  font-weight: 700;
}
.card-titles p {
  font-size: 12px;
  line-height: 16px;
  color: rgba(138, 150, 163, 0.75);
  margin: 0;
  min-height: 16px;
}
.subPay p {
  padding-left: 5px;
  text-transform: capitalize;
  color: hsl(203, 7%, 34%);
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 1px;
}
.profilesecpadd {
  padding: 10px;
  position: relative;
 left: 10px;
 /* bottom: 40px; */
}
.profileseDetailscpadd {
  padding: 10px;
  position: relative;
 /* bottom: 40px; */
 background: rgb(255 255 255);
}
span, a {
  cursor: pointer;
}
.bacbuttonposition {
  position: absolute;
  right: 20px;
  top: 4px;
  z-index: 9999 !important;
}
.bacbuttonpositionss {
  position: absolute;
  left: 11px;
  top: 30px;
}
.padds {
  padding: 15px;
}

.chats {
  width: 100%;
  height: 370px;
  background-color: white;
  overflow: auto;
}
.recivemsg {
  padding: 5px;
  max-width: 50%;
}
.msgsR {
  border: solid rgb(62 137 201) 1px;
  border-radius: 8px;
  background-color: #3e89c9;
  padding: 5px;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.sendmsg {
  padding: 5px;
  max-width: 50%;
  float: right;
}
.msgsS {
  border: solid rgb(202, 202, 202) 1px;
  border-radius: 8px;
  background-color: #edf7fb;
  padding: 5px;
}
.inlines {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.blogDes {
  color: #51595e !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

input[type="file"] {
  -webkit-text-security: disc;
  color: transparent;
  background: transparent;
  z-index: 0;
  opacity: 0;
}

label.rt-icon2-camera.uplode {
  position: absolute;
  font-size: x-large;
  left: 15px;
}

.account_icon {
  display: none;
}
.imagesacolaps{
  width: 100%;
  height: 300px;
}
.imageCount{
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 999;
  font-weight: bold;
  color: white;
}
/* .overlay img{
  margin: auto;
  width: 100% !important;
  height: 100% !important;
  padding: 4px;
  object-fit: cover;
} */
.paidprofsub{
  width: 100%;
  height: 80px;
  border: solid #e9e9e9 1px;
  padding: 2px;

}
.coverLoder{
  position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
}

.containerImage{
  max-width: 200px;
  max-height: 170px;
  width: 100%;
  height: 100%;
}
.img img{
  border-radius: 10px;
  width: 100%;
  height: 175px;
  object-fit: cover;
}
.imagecontainer1{
  width: 100%;
  height: 100%; 
}.imagecontainer1imgh{
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.imagecontainer2{
  max-width: 280px;
  max-height: 280px;
  width: 100%;
  height: 100%;
}
.imagecontainer2{
  max-width: 250px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
}
.containerImage2{
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 250px;
  margin: auto;
  overflow: hidden;
  object-fit: fill;
}
.imagecontainer3{
  max-width: 250px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 2px;
}
.containerImage3{
  width: 250px;
  object-fit: fill !important;
  height: 250px;
  margin: auto;
  overflow: hidden;
  object-fit: fill;
}
.portfolio-image2 video{
  margin: 30px;
	width: 500px;
	max-width: 85%;
}
.videocontainer2 .picon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  border-radius: 25px;
  background-color: unset;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  color: white;
  font-size: 2rem;
}

.videocontainer2 .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
  width: 100%;
  height: 200px;
  color: #006eff;
  background: black;
  position: relative;
}
.videocontainer2 .poster > div div svg {
  font-size: 1.5rem;
}
.videocontainer2 .poster > div div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  background: #006eff;
  border-radius: 100%;
  cursor: grab;
  margin: auto;
  z-index: 3;
}
.likespan{
  font-size: 12px;
  margin-top: -4px;
  margin-left: 5px;
}
.backbutton{
  position: absolute;
    top: 2px;
    left: 14px;
    z-index: 99;
}
.likeul li{
  display: inline-block;
  cursor: grab;
}
.subTitle{
  text-align: center;
    font-size: 14px;
    font-weight: 400;
}
.noComments p{
 font-size: 14px;
 font-weight: 400;
 color: rgba(100, 117, 137, 0.7);
}
.countPost{
  position: absolute;
  top: 380px;
  right: 155px;
}
.overlay{
  margin-bottom: 0.5em;
}
.messagesWrapper {
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: scroll;
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .messanger-section .chat-content .tab-box .user-chat .chat-history .avenue-messenger .chat .messages-content {
    position: relative;
    height: 70% !important;
    width: 100%;
    padding: 20px;
    overflow: auto; }

  .overlay{
    margin-bottom: .5em;
  }
  .videocontainer2 .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
    width: 100%;
    height: 150px;
    color: #006eff;
    background: black;
    position: relative;
  }
  .containerImage3 {
    width: 250px;
    object-fit: fill !important;
    height: 145px;
    margin: auto;
    overflow: hidden;
    object-fit: fill;
    border-radius: 5px;
}

  .countPost {
    position: absolute;
    top: 35%;
    right: 25%;
}
  .imgrad{
    width: 70px;
    height: 70px;
  }
  .paddingmodile{
    padding-top: 1em !important;
  }
  .imagecontainer3 {
    max-width: 250px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    margin-bottom: 2px;
}
  header {
    background-color: #0389c9;
    position: fixed;
    max-width: 100% !important;
    width: 100%;
  
    top: 0;
    right: 0;
    scrollbar-width: none;
  }
  .search-box {
    display: none;
  }

  header .header-section .header-btn.profile-btn {
    padding: 2px;
    position: fixed !important;
    left: unset !important;
    bottom: 15px;
    top: -5px !important;
    right: 5%;
  }
  .account_icon {
    display: block !important;
  }
  .content-left {
    margin-bottom: 20px;
    display: none;
  }

  .containerImage{
    max-width: 130px;
    max-height: 150px;
    width: 100%;
    height: 100%;
  }
  .img img{
    border-radius: 10px;
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .padd {
    padding-top: 4rem;
    padding-bottom: 4.41rem;
  }
  label.rt-icon2-camera.uplode.profile_pic {
    position: absolute;
    font-size: x-large;
    left: 0px;
    top: -60px;
    z-index: 0px;
  }
  header .header-section .header-btn.notification-btn {
    bottom: 10px;
  }
  .imagesacolaps{
    width: 100%;
    height: 150px;
  }

  .imagecontainer2{
    max-width: 270px;
    max-height: 185px;
    width: 100%;
    height: 150px;

  }
  .containerImage2{
    max-width: 270px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    margin: auto;
  }

}

.veticalIcon {
  position: absolute;
    top: 20px;
    font-size: 20px;
    cursor: grab;
    right: 9px;
}
.media-body h5 {
  font-family: "Montserrat", sans-serif;
  color: #0389c9;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}
.media-body h6 {
  font-size: 14px;
  color: #647589;
  font-weight: 400;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .hide_mobile {
    display: none;
  }
  header .header-section .header-left .brand-logo {
    padding: 6px 0px 0px 10px !important;
}
  div#edit.setting-dropdown.custom-dropdown.arrow-none.dropdown-sm {
    position: absolute;
    top: 10%;
    right: 5%;
  }
  form.theme-form input {
    margin-top: 5px !important;
  }

  form.theme-form .pb-4 {
    padding-bottom: 0.2rem !important;
  }
  .profile-btn .media-body.d-md-block {
    display: none;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    min-width: 40px !important;
  }
  /* hide in mobile view event & fans suggetion */
  .col-md-3  .content-right,
  .col-md-3  .suggestion-box {
    display: none;
  }
  .css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root {
    min-width: 50px;
  }
  .css-16lloyr-MuiBottomNavigation-root {
    background-color: #0389c9;
  }
  .header-right .hide_mobile {
    display: none !important;
  }
  header .mobile-fix-menu svg.MuiSvgIcon-root {
    color: #fff;
  }
}
@media (min-width: 767px) {
  .hide_desktop {
    display: none;
  }
}
.css-w4z10b-MuiStack-root {
  flex-wrap: wrap;
}
.MuiButtonBase-root {
  margin: 3px !important;
}
.media .media-body {
  margin-left: 5px;
}
.event-box .image-section img {
  width: 100%;
}
li.header-btn.custom-dropdown.profile-btn.btn-group {
  height: fit-content;
}

.selectImage {
  width: 70px;
  height: 70px;
}
.addmideaposition {
  border: solid #e7e4e4 1px;
  width: 100%;
  max-height: 70px;
  max-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin: auto;
}
.selectImageICON {
  position: absolute;
  bottom: -30px;
  left: 20px;
}
.gallery_image_uploads {
  max-height: 130px;
  max-width: 160px;
  width: 100%;
  height: 100%;
  margin: auto;
}
.post-panel .post-wrapper {
  margin-top: 10px;
}

/* .profile-box ,.suggestion-box ,.sticky-top,.create-post,.post-panel .post-wrapper,.messanger-section,.messanger-section,.message.new {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%) !important;
}

.profile-box:hover,.suggestion-box:hover,.sticky-top:hover,.create-post:hover,.post-panel .post-wrapper:hover,.messanger-section:hover,.messanger-section:hover,.message.new:hover,.selectImage:hover {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
} */
/* input:hover,
textarea:hover,
.messanger-section,
.messanger-section,
.message.new {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 5%),
    0px 3px 14px 2px rgb(0 0 0 / 5%) !important;
} */

.col-md-10 .MuiListItemButton-root.MuiListItemButton-gutters {
  border: 1px solid #6475891a;
}

.flip-button-icon {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.MySubscriptions .story-content {
  margin-left: 20%;
}
.img-wrapper.text-center {
  border: 10px solid #ffff;
}

#basic-menu .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  padding: 0px 0px;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #edf7fb;
}

.MuiBox-root::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.MuiBox-root::-webkit-scrollbar-thumb {
  background-color: #0389c9; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.profile-cover {
  box-shadow: 0px 8px 7px 0px rgb(0 0 0 / 0%) !important;
  background-color: #ffffff;
  position: relative;
}
.uplode
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  left: 15px;
}
label.rt-icon2-camera.uplode {
  left: 0px;
  bottom: 25px;
}

.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root:hover {
  color: #1976d2;
  background: transparent;
}
.privacy_manu span {
  font-size: 12px;
}
li.hide_mobile.header-btn.custom-dropdown.dropdown-lg.btn-group.message-btn {
  padding-right: 10px;
}
.dropdown-menu.show {
  z-index: 1000;
}

.image-card {
  width: 30vw;
  height: 30vw;
  object-fit: cover;
}

#lightbox-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}

.lightClose{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #3e89c9;
  color: #fff;
}
.CloseIcons{
  position: absolute;
  left: 8px;
  top: 6px;
}

p {
  margin-bottom: 1.5rem;
  font-size: .9rem;
}

img:hover, button:hover {
  cursor: pointer;
}


