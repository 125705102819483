@media (max-width: 767px){
        footer .col-xs-6 {
        width: 50% !important;
    }
    footer .css-10hburv-MuiTypography-root {
        font-size: 0.9rem;
    }
  .header-left .MuiButtonBase-root {
        margin: 3px 1% !important;
    }
    .col-sm-12.col-xs-12.col-md-6.fanssuggestion {
        width: 100%;
    }

    span.inbox.available-stats.online {
        background-color: #2bc60c;
        border: 1px solid #f8f9fa;
        border-radius: 100%;
        height: 10px;
        width: 10px;
    }

    /* Mobile Chat  */
    .search-input.icon-right.sendmessage {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 3.5em;
   
    }
    .MuiBox-root.css-1lk7enj {
        /* position: relative; */
        left: 0;
        right: 0;
        top: 4.8em;
    }
 
    section.messanger-section.mobile {
        position: fixed;
        left: 0;
        right: 0;
        top: 8.6em;
    }

   .profile-covers .d-lg-block{
       padding: 0px 10px;
   }


.photo-wrapper .col-sm-3 {
    flex: 0 0 auto;
    width: 49% !important;
    height: 200px;
}
section.user-profile {
    position: relative;
    padding: 0px 5px;
}
.sm-p-1 {
    padding: 0px 5px;
}
.description.bg-light.p-2.px-4 {
    margin: 0px 10px;
}
}

span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    color: #647589b3;
    font-family: "Roboto", sans-serif;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary:hover {
    color: #212529;
    font-family: "Roboto", sans-serif;
}
.subButton{
    text-align: right;
    font-size: 12px;
    margin-bottom: 0px;
    position: absolute;
    right: 10px;
    bottom: 32px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    z-index: 10;
}

.profile-bg {
    padding: 62px 0px 0px 0px;
    border-radius: 0 0 6px 6px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    background-color: rgba(0,0,0,.25);
}

.inbox.available-stats.online {
    background-color: #2bc60c;
    border: 1px solid #f8f9fa;
    border-radius: 100%;
    height: 10px;
    width: 10px;
}
a.dropdown-item:last-child {
    padding-bottom: 15px;
}

.user-chat .user-title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
}
.post-panel .suggestion-box .story-box .story-content h6, .suggestion-box .story-box .story-content .h6 {

    margin: 0 34%;
}

.post-panel .suggestion-box .slick-slider.slick-initialized {
    max-height: 285px;
    overflow: hidden;
}

.css-106c1u2-MuiBadge-badge {
    background-color: #2bc60c !important;
    color: #ffff !important;
}

.detail-box .blogDes {
    font-size: 14px !important;
    color: #51595e !important;
}

img {
    border-radius: 0px;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 50px !important;
    width: 50px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100% !important;
}

.post-panel.post-wrapper .MuiBox-root.css-1gsv261 {
    background-color: #fff;
    border-radius: 5px;
}
.css-13xfq8m-MuiTabPanel-root {
    padding: 0px;
}

.post-panel.post-wrapper  .section-t-space {
    margin-top: 5px;
}
img.w-100.shadow-1-strong.rounded {
    height: 100%;
    object-fit: cover;
    image-rendering: pixelated;
}

.photo-wrapper .col-sm-3 {
    flex: 0 0 auto;
    width: 33%;
    height: 200px;
}

.socialLinks li{
    padding-top: 0.5em;
    display: inline-flex;
    list-style: none;
    padding-left: 12px;
    cursor: pointer;
}
.postLinks li{
    padding-top: 0.5em;
    display: inline-flex;
    list-style: none;
    padding-left: 12px;
    cursor: pointer;
}
svg{
    cursor: pointer;    
}
ul li{
    cursor: pointer;      
}